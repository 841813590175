<template>
  <column-layout class="page" sticky-sides :no-right="!active">
    <!-- Left -->
    <template v-slot:left>
      <Menu />
    </template>

    <!-- Main -->
    <template>
      <div class="widget">
        <label class="label">
          {{ $t("contacts.book") }}
        </label>

        <div class="card pa">
          <!-- Header -->
          <div class="d-flex">
            <SearchInput
              v-model="searchInput"
              class="rounded-lg"
              style="max-width: 250px"
              @search="
                ($e) => {
                  resetData();
                  fetchData();
                }
              "
            />
          </div>

          <v-divider class="my-5" />

          <!-- Spinner -->
          <spinner
            v-if="!meta.current_page && $loading('contacts/fetch')"
            center
          />

          <!-- Alerts -->
          <v-alert v-else-if="!data.length" type="info" text class="mb-0">
            {{
              searchInput
                ? $t("common.noResultsLong")
                : $t("common.noDataFound")
            }}
          </v-alert>

          <!-- Items -->
          <div class="items d-flex flex-wrap" style="gap: 20px">
            <UserItem
              v-for="item in data"
              :key="item.id"
              :user="item.contact"
              class="mb-5"
              @click="active = item.contact"
            />
          </div>

          <!-- Load more -->
          <div v-if="$utils.canLoadMore(meta)" class="text-center">
            <v-btn
              color="primary"
              small
              rounded
              @click="fetchData"
              :loading="!!$loading('contacts/fetch')"
            >
              {{ $t("common.loadMore") }}
            </v-btn>
          </div>
        </div>
      </div>
    </template>

    <!-- Right -->
    <template v-if="active" v-slot:right>
      <ContactCard
        :key="active.id"
        :user="active"
        @close="active = null"
        class="label-empty"
      />
    </template>
  </column-layout>
</template>

<script>
import ContactCard from "@/components/app/contacts/ContactCard.vue";
import Menu from "@/components/app/Menu.vue";
import SearchInput from "@/components/common/SearchInput.vue";
import UserItem from "@/components/app/users/UserItem.vue";

export default {
  metaInfo: { title: "Contacts book" },

  components: { ContactCard, Menu, SearchInput, UserItem },

  data: () => ({
    searchInput: "",
    active: null,
    data: [],
    meta: {
      current_page: 0,
    },
  }),

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      const params = {
        page: this.meta.current_page + 1,
        per_page: 24,
      };

      if (this.searchInput) {
        params["search[contact.username]"] = this.searchInput;
      }

      this.$store
        .dispatch("contacts/fetch", params)
        .then((res) => {
          this.meta = res.meta;
          this.data.push(...res.data);
        })
        .catch(() => {
          this.$toast.error("Error while fetching contacts.");
        });
    },

    resetData() {
      this.data = [];
      this.meta = {
        current_page: 0,
      };
    },
  },
};
</script>

<style lang="scss"></style>
