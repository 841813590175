<template>
  <v-hover v-if="showable" v-slot="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      :color="$attrs.color || 'primary'"
      :icon="icon"
      :textOnly="!textOnly"
      :loading="loading"
      :disabled="!user.username"
      @click="onClick"
    >
      <v-icon :left="!icon" v-if="!textOnly">{{ getIcon(hover) }}</v-icon>

      <template v-if="!icon">
        {{ getText(hover) }}
      </template>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    user: Object,
    icon: Boolean,
    unfollowable: Boolean,
    textOnly: Boolean,
  },

  computed: {
    showable() {
      return (
        this.user.id !== this.$user.id &&
        (!this.user.is_followed || this.unfollowable) &&
        !this.user.is_blocked &&
        !this.$user.blocked_users.includes(String(this.user.id))
      );
    },

    loading() {
      return (
        this.$loading("follows/follow") === this.user.id ||
        this.$loading("follows/unfollow") === this.user.id ||
        this.$loading("follows/cancelRequest") === this.user.id
      );
    },
  },

  methods: {
    onClick() {
      if (this.user.is_followed) {
        this.unfollow();
      } else if (this.user.is_pending) {
        this.cancelRequest();
      } else {
        this.follow();
      }
    },

    follow() {
      this.$store.dispatch("follows/follow", this.user.id).then((res) => {
        if (res.is_pending) {
          this.$set(this.user, "is_pending", true);
        } else {
          this.$set(this.user, "is_followed", true);
          this.$set(
            this.user,
            "followers_count",
            this.user.followers_count + 1
          );
          this.$set(
            this.$user,
            "following_count",
            this.$user.following_count + 1
          );
        }
      });
    },

    unfollow() {
      this.$store.dispatch("follows/unfollow", this.user.id).then(() => {
        this.$set(this.user, "is_followed", false);
        this.$set(this.user, "followers_count", this.user.followers_count - 1);
        this.$set(
          this.$user,
          "following_count",
          this.$user.following_count - 1
        );
      });
    },

    cancelRequest() {
      this.$store.dispatch("follows/cancelRequest", this.user.id).then(() => {
        this.$set(this.user, "is_pending", false);
      });
    },

    getIcon(hover) {
      if (this.user.is_followed) {
        return hover ? "mdi-account-minus" : "mdi-account-check";
      } else if (this.user.is_pending) {
        return hover ? "mdi-account-remove" : "mdi-account-clock";
      } else {
        return "mdi-account-plus";
      }
    },

    getText(hover) {
      if (this.user.is_followed) {
        return hover ? this.$t("users.unfollow") : this.$t("users.following");
      } else if (this.user.is_pending) {
        return hover ? this.$t("common.cancel") : this.$t("common.pending");
      } else {
        return this.$t("users.follow");
      }
    },
  },
};
</script>
