<template>
  <v-dialog v-model="dialog" width="600" @click:outside="close">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" v-bind="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="secondary" rounded small block>
          <v-icon v-if="contact" left>mdi-pencil</v-icon>
          {{ $t("common.edit") }}
        </v-btn>
      </slot>
    </template>

    <v-form v-model="formValid" @submit.prevent="submit" ref="form">
      <v-card>
        <!-- Header -->
        <v-card-title>
          <h3>
            {{ $t("contacts.edit") }}
          </h3>
          <v-spacer />
          <close-button @click="close" />
        </v-card-title>

        <v-divider />

        <!-- Main -->
        <v-card-text>
          <!-- Name -->
          <v-text-field
            v-model="formData.full_name"
            :label="$t('contacts.fullName')"
            :rules="[]"
            :maxlength="$config.contacts.maxNameLength"
            background-color="ocean-dark"
            filled
          />

          <!-- Phone -->
          <v-text-field
            v-model="formData.phone"
            :label="$t('contacts.phone')"
            :rules="[$rules.phone]"
            background-color="ocean-dark"
            filled
          />

          <!-- Company -->
          <v-text-field
            v-model="formData.company"
            :label="$t('contacts.company')"
            :rules="[]"
            background-color="ocean-dark"
            filled
          />

          <!-- Company link -->
          <v-text-field
            v-model="formData.company_link"
            :label="$t('contacts.companyLink')"
            :rules="[$rules.url]"
            background-color="ocean-dark"
            filled
          />

          <!-- Birthday -->
          <v-text-field
            v-model="formData.birthday"
            v-mask="'##.##.####'"
            :label="$t('contacts.birthday')"
            :rules="[$rules.date]"
            placeholder="DD.MM.YYYY"
            background-color="ocean-dark"
            filled
          />

          <!-- Address -->
          <v-text-field
            v-model="formData.address"
            :label="$t('contacts.address')"
            :rules="[]"
            background-color="ocean-dark"
            filled
          />

          <!-- City -->
          <v-text-field
            v-model="formData.city"
            :label="$t('contacts.city')"
            :rules="[]"
            background-color="ocean-dark"
            filled
          />

          <!-- Country -->
          <v-select
            v-model="formData.country"
            :label="$t('contacts.country')"
            :items="countries"
            item-text="name"
            item-value="name"
            background-color="ocean-dark"
            filled
            menu-props="offsetY"
          />

          <api-response :response="formResponse" class="mb-0" />
        </v-card-text>

        <!-- Footer -->
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            type="submit"
            rounded
            min-width="130"
            :disabled="!formValid"
            :loading="!!$loading('contacts/update')"
          >
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import countries from "@/config/countries.json";

export default {
  props: {
    user: Object,
    contact: Object,
  },

  data: () => ({
    dialog: false,
    formData: {
      full_name: "",
      phone: "",
      company: "",
      company_link: "",
      birthday: "",
      address: "",
      city: "",
      country: "",
    },
    formValid: false,
    formResponse: null,
    countries,
  }),

  watch: {
    dialog(val) {
      if (val) {
        this.initForm();
      }
    },
  },

  methods: {
    submit() {
      this.update();
    },

    update() {
      this.$store
        .dispatch("contacts/update", {
          id: this.user.id,
          payload: this.formData,
        })
        .then((contact) => {
          this.$utils.updateObject(this.contact, contact, this);
          this.close();
        })
        .catch((data) => {
          this.formResponse = data;
        });
    },

    close() {
      this.dialog = false;
      this.$refs.form.reset();
    },

    initForm() {
      if (this.contact) {
        this.$utils.updateObject(this.formData, this.contact, this);
      }
    },
  },
};
</script>
