<template>
  <div class="contact-card widget card pa">
    <!-- Loader -->
    <spinner v-if="$loading('contacts/fetchByUser')" center />

    <template v-else-if="contact">
      <!-- Close -->
      <close-button
        class="pos-abs"
        style="top: 10px; right: 10px"
        @click="$emit('close')"
      />

      <!-- Avatar -->
      <div class="d-flex justify-center">
        <v-avatar class="avatar" size="80">
          <v-img
            :src="$utils.userImage(user, '80x80')"
            width="80"
            height="80"
            :alt="user.username"
          />
        </v-avatar>
      </div>

      <!-- Username -->
      <div class="username font-size-18 font-weight-bold text-center mt-3">
        {{ user.username }}
      </div>

      <!-- Fields -->
      <div class="fields font-size-14 my-5">
        <div
          v-for="field in fields"
          :key="field.label"
          class="field d-flex border-bottom-except-last py-2"
        >
          <label class="text--secondary" style="width: 40%; max-width: 150px">
            {{ field.label }}
          </label>
          <span class="font-weight-medium">{{ field.value }}</span>
        </div>
      </div>

      <!-- Button -->
      <ContactFormModal :user="user" :contact="contact" />
    </template>
  </div>
</template>

<script>
import ContactFormModal from "./ContactFormModal.vue";

export default {
  components: { ContactFormModal },

  props: {
    user: Object,
  },

  data: () => ({
    contact: null,
  }),

  computed: {
    fields() {
      return [
        { label: this.$t("contacts.fullName"), value: this.contact.full_name },
        { label: this.$t("contacts.phone"), value: this.contact.phone },
        { label: this.$t("contacts.company"), value: this.contact.company },
        {
          label: this.$t("contacts.companyLink"),
          value: this.contact.company_link,
        },
        { label: this.$t("contacts.birthday"), value: this.contact.birthday },
        { label: this.$t("contacts.address"), value: this.contact.address },
        { label: this.$t("contacts.city"), value: this.contact.city },
        { label: this.$t("contacts.country"), value: this.contact.country },
      ];
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store
        .dispatch("contacts/fetchByUser", this.user.id)
        .then((contact) => {
          this.contact = contact;
        })
        .catch(() => {
          this.$toast.error("Error while fetching user.");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
